import {Controller} from "stimulus";

export default class extends Controller {
  static values = {
    formId: String,
    inputsToBeCleared: Array,
  };

  clear() {
    const form = document.getElementById(this.formIdValue);

    this.inputsToBeClearedValue.forEach((element) => {
      const event = new Event("change", {bubbles: true});
      const inputName = element["name"];
      const inputValue = element["value"];

      const input = form.querySelector(`[name='${inputName}']`);

      if (["checkbox", "radio"].indexOf(input.type) != -1) {
        const option = form.querySelector(`[name='${inputName}'][value='${inputValue}']`);
        option.checked = false;
        option.dispatchEvent(event);
      } else {
        input.value = "";
        input.dispatchEvent(event);
      }
    });

    form.submit();
    this.element.remove();
  }
}
