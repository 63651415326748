import {Application} from "stimulus";
import {definitions as controllers} from "stimulus:./controllers";
import {definitions as baseComponentControllers} from "stimulus:../../../app/components";
import {definitions as componentControllers} from "stimulus:../../../app/mounts/recruitment/components";

const application = Application.start();
application.load(controllers);
application.load(baseComponentControllers);
application.load(componentControllers);

import AutocompleteAddressController from "src/controllers/autocomplete_address_controller";
application.register("autocomplete-address", AutocompleteAddressController);

import AutocompleteSuburbController from "src/controllers/autocomplete_suburb_controller";
application.register("autocomplete-suburb", AutocompleteSuburbController);

import AddableNestedAttributesController from "../../../app/mounts/admin/components/admin/addable_nested_attributes_controller.js";
application.register("addable-nested-attributes", AddableNestedAttributesController);

import DisableAttachmentsController from "src/controllers/disable_attachments_controller";
application.register("disable-attachments", DisableAttachmentsController);

import DisableLinksController from "src/controllers/disable_links_controller";
application.register("disable-links", DisableLinksController);

import RemovableNestedAttributesController from "../../../app/mounts/admin/components/admin/removable_nested_attributes_controller.js";
application.register("removable-nested-attributes", RemovableNestedAttributesController);

import TooltipController from "../../../app/mounts/admin/components/admin/with_tooltip_controller.ts";
application.register("admin--with-tooltip", TooltipController);

import DependentFormSectionController from "src/admin/controllers/dependent_form_section_controller.ts";
application.register("dependent-form-section", DependentFormSectionController);
