import {Controller} from "stimulus";

export default class extends Controller {
  static values = {
    closable: {type: Boolean, default: true},
    redirect: String,
  };

  connect() {
    document.addEventListener("keyup", this.keyup);
  }

  disconnect() {
    document.removeEventListener("keyup", this.keyup);
  }

  close() {
    if (!this.closableValue) return;

    this.dispatch("close", {prefix: "tlw-modal", bubbles: true});
    this.element.classList.add("animate-fade-out");

    if (this.hasRedirectValue) {
      const currentPath = window.location.href.split(window.location.host)[1];

      if (currentPath != this.redirectValue) {
        Turbo.visit(this.redirectValue);
      }
    }

    // Wait for fade animation to finish before removing element from DOM
    setTimeout(() => {
      this.element.parentElement.removeAttribute("src");
      this.element.remove();
    }, 100);
  }

  keyup = (event) => {
    if (event.keyCode === 27) {
      this.close();
    }
  };
}
