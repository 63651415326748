import {Controller} from "stimulus";
import kebabCase from "lodash/kebabCase";

export default class ConnectCompanySignup extends Controller {
  static targets = ["slug", "timezone"];

  declare readonly slugTarget: HTMLInputElement;
  declare readonly timezoneTarget: HTMLInputElement;

  initialize() {
    if (Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone?.indexOf("Australia") === 0) {
      this.timezoneTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone.replace("Australia/", "");
    }
  }

  updateSlug(event) {
    if (this.slugTarget.dataset.dirty) return;
    this.slugTarget.value = kebabCase(event.target.value.toLowerCase());
  }

  handleChange(event) {
    event.target.dataset.dirty = "true";

    if (event.data !== "-") {
      event.target.value = kebabCase(event.target.value.toLowerCase());
    }
  }

  whitelistKeys(event) {
    if (event.key.length === 1 && !/[A-Za-z0-9-]/.test(event.key)) {
      event.preventDefault();
    }
  }
}
