import {Controller} from "stimulus";

const paths = {
  password: "M6.288 10c0 2.122 1.653 3.825 3.712 3.825 2.06 0 3.712-1.703 3.712-3.825S12.059 6.175 10 6.175c-2.06 0-3.712 1.703-3.712 3.825ZM1 10s3.712-6 9-6c5.364 0 9 6 9 6s-2.644 6-9 6c-6.305 0-9-6-9-6Zm9.025 1.624c.865 0 1.577-.707 1.577-1.624 0-.89-.712-1.624-1.577-1.624-.864 0-1.576.733-1.576 1.624 0 .89.712 1.624 1.576 1.624Z",
  text: "M16.595 16.584a.671.671 0 0 1-.973 0L3.41 4.387a.697.697 0 0 1 0-.98c.269-.27.72-.26.98 0L16.596 15.61c.269.27.26.704 0 .973ZM13.712 10c0 .25-.023.493-.067.73l2.747 2.746C18.191 11.836 19 10 19 10s-3.636-6-9-6a7.5 7.5 0 0 0-2.603.48l1.785 1.787c.263-.06.537-.092.818-.092 2.06 0 3.712 1.703 3.712 3.825Zm-.995 5.605-1.879-1.877a3.62 3.62 0 0 1-.838.097c-2.06 0-3.712-1.703-3.712-3.825a4 4 0 0 1 .07-.746L3.82 6.721C2.047 8.308 1 10 1 10s2.695 6 9 6c.995 0 1.9-.147 2.717-.395Z",
};

export default class extends Controller {
  static targets = ["element"];

  initialize() {
    const button = document.createElement("button");

    button.setAttribute("type", "button");
    button.setAttribute("tabindex", "-1");
    button.setAttribute(
      "style",
      "position: absolute; top: 50%; right: 1rem; margin: -10px 0 0; padding: 0; width: 20px; height: 20px; color: currentColor; appearance: none; background: transparent; border: 0; cursor: pointer;"
    );
    button.innerHTML = `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="${paths.password}" clip-rule="evenodd"/></svg>`;

    this.buttonTarget = button;
  }

  connect() {
    const containerElement = this.elementTarget.parentElement;
    this.elementTarget.style.paddingRight = "44px";
    this.buttonTarget.addEventListener("click", this.toggle.bind(this));
    containerElement.style.position = "relative";
    containerElement.appendChild(this.buttonTarget);
  }

  disconnect() {
    this.buttonTarget.removeEventListener("click", this.toggle.bind(this));
    this.buttonTarget.remove();
  }

  toggle() {
    if (this.hasElementTarget) {
      const newType = this.elementTarget.getAttribute("type") === "text" ? "password" : "text";

      // Update button icon
      this.buttonTarget.querySelector("path").setAttribute("d", paths[newType]);

      // Update input type & focus
      this.elementTarget.setAttribute("type", newType);
      this.elementTarget.focus();
    }
  }
}
