import {Controller} from "stimulus";
import {autoUpdate, computePosition, flip, shift, offset, arrow} from "@floating-ui/dom";
import {Placement} from "@floating-ui/dom";

export default class FloatingPositionerController extends Controller<HTMLElement> {
  static targets = ["reference", "floating", "arrow"];
  static values = {
    offset: {
      type: Number,
      default: 0,
    },
    placement: {
      type: String,
      default: "bottom",
    },
    arrowPadding: {
      type: Number,
      default: 0,
    },
  };

  declare referenceTarget: HTMLElement;
  declare floatingTarget: HTMLElement;
  declare arrowTarget: HTMLElement;
  declare hasArrowTarget: boolean;

  declare offsetValue: number;
  declare placementValue: Placement;
  declare arrowPaddingValue: number;

  declare _cleanup: () => void;

  declare updating: boolean;

  position() {
    this._cleanup = autoUpdate(this.referenceTarget, this.floatingTarget, this.updatePosition);
    this.updating = true;
  }

  cleanup() {
    this._cleanup?.call(this);
    this.updating = false;
  }

  toggle() {
    if (this.updating) {
      this.cleanup();
    } else {
      this.position();
    }
  }

  updatePosition = () => {
    computePosition(this.referenceTarget, this.floatingTarget,
      this.buildOptions()
    ).then(({placement, x, y, middlewareData}) => {
      Object.assign(this.floatingTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });

      if (middlewareData.arrow) {
        const {x: arrowX, y: arrowY} = middlewareData.arrow;
        const side = placement.split("-")[0];

        const staticSide = {
          top: "bottom",
          right: "left",
          bottom: "top",
          left: "right",
        }[side];

        Object.assign(this.arrowTarget.style, {
          left: x != null ? `${arrowX}px` : "",
          top: y != null ? `${arrowY}px` : "",
          [staticSide!]: `${-this.arrowTarget.offsetWidth / 2}px`,
        });
      }
    });
  };

  disconnect(): void {
    this.cleanup();
  }

  private buildOptions = () => {
    const options = {
      placement: this.placementValue,
      middleware: [
        offset(this.offsetValue),
        shift(),
        flip(),
      ],
    };

    if (this.hasArrowTarget) {
      options.middleware.push(arrow({element: this.arrowTarget, padding: this.arrowPaddingValue}));
    }

    return options;
  };
}
