import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["email", "button"];
  static values = {url: String};

  connect() {
    this.urlValue = this.buttonTarget.getAttribute("href");
    this.locationTarget = this.element.querySelector("input[data-autocomplete-suburb-target='search']");

    ["change", "keyup", "blur"].forEach(action => {
      this.emailTarget.addEventListener(action, (event) =>
        this.updateUrlParam(event.target.value)
      );

      this.locationTarget.addEventListener(action, () => {
        this.locationTarget.setCustomValidity("");
      });
    });

    this.element.addEventListener("submit", this.handleSubmit);

    this.buttonTarget.addEventListener("click", this.handleCodeGenerate);

    if (this.emailTarget.value) this.updateUrlParam(this.emailTarget.value);
  }

  handleCodeGenerate = (event) => {
    if (!this.emailTarget.checkValidity()) {
      event.preventDefault();
      this.emailTarget.reportValidity();
    }
  };

  updateUrlParam = (email) => {
    this.buttonTarget.setAttribute(
      "href",
      [this.urlValue, encodeURIComponent(email)].join("?email=")
    );
  };

  handleSubmit = (event) => {
    if (this.element.querySelector("input[name='recruitment_candidate[lat]']").value === "") {
      event.preventDefault();

      this.locationTarget.setCustomValidity("Please select a location from the list");
      this.locationTarget.reportValidity();
    }
  };
}
