// Prevent links from being accepted by the Trix editor
// Any links pasted intro the editor will be removed
// The link toolbar button is hidden
//
// Use from a ruby template
// <%= f.rich_text_area data: {controller: "disable-links", disable_links_target: "field"} %>
import {Controller} from "stimulus";

interface EditorElement extends HTMLElement {
  editor: any;
}

export default class extends Controller {
  static targets = ["field"];
  declare fieldTargets: EditorElement[];

  connect() {
    this.fieldTargets.forEach((field) => {
      field.addEventListener("trix-before-paste", (event: any) => {
        if (event?.paste?.html) {
          event.paste.html = this.removeLinkTags(event.paste.html);
        }
      });

      const toolbar = field.querySelector("trix-toolbar");
      if (field.editor) {
        // trix initialized before this controller connected
        this.hideLinkButton(field.editor.element.toolbarElement);
      } else if (toolbar) {
        // trix has initialized but not assigned field.editor yet
        this.hideLinkButton(toolbar);
      } else {
        // trix hasn't initialized yet
        field.addEventListener("trix-initialize", (event: any) => {
          this.hideLinkButton(event.target.toolbarElement);
        });
      }
    });
  }

  hideLinkButton(toolbarElement) {
    toolbarElement.querySelector(".trix-button--icon-link").style.display = "none";
  }

  removeLinkTags = (html: string) => {
    const container = document.createElement("div");
    container.innerHTML = html;
    const linkElements = container.querySelectorAll("a, audio, embed, iframe, img, object, source, svg, video");

    Array.from(linkElements).forEach(element => {
      const elementParent = element.parentNode;

      while (element.firstChild) {
        elementParent?.insertBefore(element.firstChild, element);
      }

      elementParent?.removeChild(element);
    });

    return container.innerHTML;
  };
}
