import {Controller} from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("click", this.clickOutside);
    document.addEventListener("keyup", this.escape);
  }

  disconnect() {
    document.removeEventListener("click", this.clickOutside);
    document.removeEventListener("keyup", this.escape);
  }

  close = () => {
    this.element.removeAttribute("open");
  };

  clickOutside = ({target}) => {
    if (this.element.open && !this.element.contains(target)) {
      this.close();
    }
  };

  escape = ({keyCode}) => {
    if (this.element.open && keyCode === 27) {
      this.close();
    }
  };
}
