import {Controller} from "stimulus";

export default class MapController extends Controller<HTMLElement> {
  map: google.maps.Map;

  static values = {
    zoom: Number,
    address: String,
    showMarker: Boolean,
    origin: String,
    destination: String,
    waypoints: Array,
    travelDistanceSelector: String,
    travelInformationSelector: String,
  };

  declare zoomValue: number;
  declare showMarkerValue: boolean;
  declare addressValue: string | undefined;
  declare originValue: string | undefined;
  declare destinationValue: string | undefined;
  declare waypointsValue: string[];
  declare travelDistanceSelectorValue: string;
  declare travelInformationSelectorValue: string;

  connect() {
    this.map = new google.maps.Map(this.element, {zoom: this.zoomValue});

    if (this.addressValue) {
      this.renderAddress();
    } else if (this.originValue && this.destinationValue) {
      this.renderRoute();
    }
  }

  renderAddress() {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({address: this.addressValue}, (results, status) => {
      if (results && results.length > 0 && status == google.maps.GeocoderStatus.OK) {
        this.map.setCenter(results[0].geometry.location);
        if (this.showMarkerValue) {
          new google.maps.Marker({
            map: this.map,
            position: results[0].geometry.location,
          });
        }
      }
    });
  }

  renderRoute() {
    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(this.map);

    const directionsService = new google.maps.DirectionsService();
    directionsService
      .route({
        origin: {query: this.originValue},
        destination: {query: this.destinationValue},
        waypoints: this.waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
        this.handleDirectionsResponse(response);
      })
      .catch(console.error);
  }

  handleDirectionsResponse(response: google.maps.DirectionsResult) {
    this.renderTravelInformation(response);
    this.renderTravelDistances(response);
  }

  renderTravelInformation(directions: google.maps.DirectionsResult) {
    if (!this.travelInformationSelectorValue) return;

    document.querySelectorAll<HTMLElement>(this.travelInformationSelectorValue).forEach(target => {
      const leg = this.getLegForIndex(directions.routes[0].legs, target);
      if (!leg) return;

      target.innerText = `${leg.duration?.text} (${leg.distance?.text})`;
    });
  }

  renderTravelDistances(directions: google.maps.DirectionsResult) {
    if (!this.travelDistanceSelectorValue) return;

    document.querySelectorAll<HTMLElement>(this.travelDistanceSelectorValue).forEach(target => {
      const leg = this.getLegForIndex(directions.routes[0].legs, target);
      if (!leg) return;

      target.innerText = `(${leg.distance?.text})`;
    });
  }

  getLegForIndex(legs: google.maps.DirectionsLeg[], target: HTMLElement) {
    const legIndex = target.dataset.legIndex as string;
    if (!legIndex) return console.warn("Leg index missing for index", target);
    const leg = legs[parseInt(legIndex)];
    if (!leg) return console.warn("Leg data missing for target", target);
    return leg;
  }

  get waypoints() {
    const waypoints = this.waypointsValue.map(location => ({location}));
    if (waypoints.length > 10) {
      console.warn(`Passing more than 10 waypoints to the Google Maps directions API incurs higher charges. ${waypoints.length} waypoints were passed.`);
    }
    return waypoints.slice(0, 9);
  }
}
