import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["dropzone", "input"];

  connect() {
    this.setupDropzone();
    document.body.addEventListener("dragenter", this.handleDragEnter);
    document.body.addEventListener("dragleave", this.handleDragEnd);
    document.body.addEventListener("dragend", this.handleDragEnd);
  }

  disconnect() {
    document.body.removeEventListener("dragenter", this.handleDragEnter);
    document.body.removeEventListener("dragleave", this.handleDragEnd);
    document.body.removeEventListener("dragend", this.handleDragEnd);
  }

  stopPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  handleDragEnter = (event) => {
    this.stopPropagation(event);
    this.dropzoneTarget.classList.remove("hidden");
  };

  handleDragEnd = (event) => {
    this.stopPropagation(event);

    const xStartOffset = 20; // Safari has a drag area on the left side 🤷‍♂️

    // Only trigger event when cursor has left window
    if (
      event.clientX < xStartOffset ||
      event.clientX >= window.innerWidth ||
      event.clientY < 1 ||
      event.clientY >= window.innerHeight
    ) {
      this.dropzoneTarget.classList.add("hidden");
    }
  };

  setupDropzone() {
    // Prevent event bubbling
    ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach((event) =>
      this.dropzoneTarget.addEventListener(event, this.stopPropagation)
    );

    this.dropzoneTarget.addEventListener("dragover", () =>
      this.dropzoneTarget.classList.remove("opacity-75")
    );

    this.dropzoneTarget.addEventListener("dragleave", () =>
      this.dropzoneTarget.classList.add("opacity-75")
    );

    this.dropzoneTarget.addEventListener("dragend", () =>
      this.dropzoneTarget.classList.add("hidden")
    );

    this.dropzoneTarget.addEventListener("drop", (event) => {
      const files = event.dataTransfer.files;

      this.dropzoneTarget.classList.add("hidden");
      this.inputTarget.files = files;

      // Fire change event
      const changeEvent = new Event("change");
      this.inputTarget.dispatchEvent(changeEvent);
    });
  }
}
