import {Controller} from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    level: String,
    levelThreeSeenAt: String,
    levelTwoSeenAt: String,
    url: String,
  };

  static targets = ["checkbox"];

  connect() {
    const formData = new FormData();
    formData.append("level_two_seen_at", this.levelTwoSeenAtValue);
    formData.append("level_three_seen_at", this.levelThreeSeenAtValue);

    this.element.addEventListener("tlw-modal:close", () => {
      formData.append(`level_${this.levelValue}_seen_at`, new Date().toISOString());
      this.updateOnboarding(formData);
    });
  }

  updateOnboarding = (data) => {
    Rails.ajax({
      url: this.urlValue,
      type: "PATCH",
      data,
    });
  };

  toggleMenu() {
    this.checkboxTarget.click();
  }
}
