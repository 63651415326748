import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  connect() {
    document.addEventListener("keyup", this.keyup);
  }

  disconnect() {
    document.removeEventListener("keyup", this.keyup);
  }

  open() {
    this.elementTarget.classList.remove("hidden");
    this.elementTarget.classList.add("block");
  }

  close() {
    this.elementTarget.classList.remove("block");
    this.elementTarget.classList.add("hidden");
  }

  keyup = (event) => {
    if (event.keyCode === 27) {
      this.close();
    }
  };
}
