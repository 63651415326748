import {Controller} from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    this.datePicker = flatpickr(this.element, {
      enableTime: false,
      altInput: true,
      monthSelectorType: "static",
      altInputClass: [...Array.from(this.element.classList), "flatpickr-input"].join(" "),
      altFormat: "D, j M Y",
      dateFormat: "Y-m-d H:i",
      defaultDate: this.element.getAttribute("value"),
      onReady(_dates, _currentDateString, {_input}) {
        _input.setAttribute("aria-labelledby", this.element.getAttribute("aria-labelledby"));
      },
    });
  }
}
