import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "input", "button"];

  connect() {
    this.currentValue = this.inputTarget.value;
    this.inputTarget.addEventListener("input", this.autoResizeInput);

    this.inputTarget.addEventListener("keydown", (event) => {
      if (event.metaKey && event.key === "Enter") this.buttonTarget.click();
    });

    this.inputTarget.addEventListener("keyup", (event) => {
      if (event.key === "Escape") {
        this.inputTarget.value = this.currentValue;
        this.checkboxTarget.click();
      }
    });
  }

  autoResizeInput = () => {
    this.inputTarget.style.height = "auto"; // Set to single row height
    this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`;
  };

  focus() {
    const textareaEl = this.inputTarget;

    setTimeout(() => {
      const end = textareaEl.value.length;

      this.currentValue = textareaEl.value;
      textareaEl.setSelectionRange(end, end);
      textareaEl.focus();
      this.autoResizeInput();
    });
  }
}
