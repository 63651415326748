import {Turbo} from "@hotwired/turbo-rails";
Turbo.session.drive = true;

import Rails from "@rails/ujs";
Rails.start();

// Address search
import "src/admin/components/ondocument";
import "src/admin/components/address_picker";

import "src/recruitment/load_stimulus_controllers";

import "trix";

// Bugsnag
import "src/common/bugsnag";
