/* ondocument
 *
 * ondocumentadd and ondocumentremove use MutationObservers to determine when
 * matching elements are added or removed from the DOM. They are a superior
 * alternative to: DOMContentLoad, placing JavaScript in script tags at the end
 * of the body, or awkwardly using Turbolinks events to detect when the body
 * was replaced.
 *
 * These two functions
 *
 * ## Usage
 *
 * `ondocumentadd` takes two arguments. The first argument is a selector to
 * match. The second argument is callback function that is called when an
 * element matching that selector is added to the DOM.
 *
 * `ondocumentremove` takes two arguments. The first argument is a reference to
 * an element already in the DOM. The second argument is a callback function
 * that is called when the element is removed from the DOM.
 *
 *     <script>
 *       ondocumentadd("[data-foo]", function(node) {
 *         // node has been added into the DOM.
 *
 *         ondocumentremove(node, function() {
 *           // node has been removed from the DOM.
 *
 *         });
 *       });
 *
 *       var el = document.createElement();
 *       el.setAttribute("data-foo", "true");
 *       document.documentElement.appendChild(el); // Triggers the `ondocumentadd` callback.
 *     </script>
 */
(function() {
  "use strict";

  var seedUID = 1;
  var addedCallbacks = [];
  var removedCallbacks = {};

  var array = function(arrayish) {
    return Array.prototype.slice.call(arrayish);
  };

  var observer = new MutationObserver(function(mutations) {
    var i = 0;
    var l = mutations.length;
    for (; i < l; i++) {
      var mutation = mutations[i];
      var nodeName = mutation.target.nodeName;
      if ({TITLE: true, HEAD: true, META: true, STYLE: true, SCRIPT: true}[nodeName]) {
        continue;
      }

      var addedNodes = array(mutation.addedNodes);
      addedNodes.forEach(function(addedNode) {
        if (typeof addedNode.querySelectorAll === "undefined") {
          return;
        }

        // Trigger callbacks if the added node or any of its descendants match the selectors.
        addedCallbacks.forEach(function(callback) {
          if (addedNode.matches(callback.sel)) { // "childlist"
            callback.fn(addedNode);
          } else {
            var fn = callback.fn;
            var nodes = array(addedNode.querySelectorAll(callback.sel)); // "subtree"
            nodes.forEach(function(node) {
              fn(node);
            });
          }
        });
      });

      var removedNodes = array(mutation.removedNodes);
      removedNodes.forEach(function(removedNode) {
        if (typeof removedNode.querySelectorAll === "undefined") {
          return;
        }

        var actionNodeRemoval = function(node) {
          var uid = node.getAttribute("ondocumentremove");
          if (!uid) {
            return;
          }

          var fn = removedCallbacks[uid];
          if (typeof fn !== "function") {
            return;
          }

          fn.call(node); // set `this` to the removed node inside the callback
          delete removedCallbacks[uid];
        };

        if (removedNode.matches("[ondocumentremove]")) {
          actionNodeRemoval(removedNode);
        } else {
          var nodes = array(removedNode.querySelectorAll("[ondocumentremove]"));
          nodes.forEach(function(node) {
            actionNodeRemoval(node);
          });
        }
      });
    }
  });

  // Expose ondocumentadd
  window.ondocumentadd = function(sel, fn) {
    addedCallbacks.push({sel: sel, fn: fn});
  };

  // Expose ondocumentremove
  window.ondocumentremove = function(node, fn) {
    var uid = new Date().getTime().toString() + (seedUID++).toString();
    node.setAttribute("ondocumentremove", uid);
    removedCallbacks[uid] = fn;
  };

  // Bootstrap
  window.addEventListener("DOMContentLoaded", function bootstrap() {
    window.removeEventListener("DOMContentLoaded", bootstrap);

    addedCallbacks.forEach(function(callback) {
      var fn = callback.fn;
      var nodes = array(document.querySelectorAll(callback.sel));
      nodes.forEach(function(node) {
        fn(node);
      });
    });

    observer.observe(document.documentElement, {childList: true, subtree: true});
  });
})();
