import {Controller} from "stimulus";

export default class extends Controller<HTMLElement> {
  connect() {
    this.handleWrappingStyleCalculation();
    window.addEventListener("resize", this.handleWrappingStyleCalculation);
  }

  disconnect() {
    window.removeEventListener("resize", this.handleWrappingStyleCalculation);
  }

  handleWrappingStyleCalculation = () => {
    Array.from(this.element.children).forEach((el: HTMLElement) => {
      const isWrappedElement = el.offsetTop > this.element.offsetTop;

      if (isWrappedElement) {
        el.style.borderLeftWidth = "0";
        el.style.paddingLeft = "0";
      } else {
        el.removeAttribute("style");
      }
    });
  };
}
