interface Address {
  street1?: string;
  locality?: string;
  region?: string;
  postcode?: string;
  country?: string;
}

export const mapGooglePlaceAddressComponentsToAddress = function(
  components: google.maps.GeocoderAddressComponent[],
  search: string
): Address {
  const dig = function(components: google.maps.GeocoderAddressComponent[], type: string, property: string): string | undefined {
    const component = components.find(function(component) {
      return component.types.find(function(otherType) {
        return type === otherType;
      });
    });
    if (component) {
      return component[property];
    }
  };

  /*
   * https://stackoverflow.com/questions/17936689/google-places-autocomplete-suggestions-with-unit-no-subpremise-is-not-coming-in
   * Google Places API doesn't have complete knowledge of unit and street numbers
   * This attempts to put the unit number back in when it is missing from the API's response
   * Test cases:
   * 75/209 Harris Street, Hawthorne            subpremise and streetNumber are undefined
   * 101 North Quay, Brisbane City              subpremise is undefined
   * 1b/123 Clayton Road, Clayton               all are defined
   */
  const formatStreetByPatchingSubpremise = function(
    userSearch: string,
    subpremise: string | undefined,
    streetNumber: string | undefined,
    route: string | undefined
  ): string | undefined {
    let street = route || "";
    if (streetNumber !== undefined) {
      if (subpremise !== undefined) {
        street = `${subpremise}/${streetNumber} ${route}`;
      } else {
        street = `${streetNumber} ${route}`;
      }
    }
    const unitAndStreetNumberRegex = /^[0-9a-zA-Z]+\/[0-9a-zA-Z]+ /;
    const match = unitAndStreetNumberRegex.exec(userSearch);

    if (match && match[0] && !unitAndStreetNumberRegex.test(street)) {
      return match[0] + street;
    } else {
      return street;
    }
  };

  return {
    street1: formatStreetByPatchingSubpremise(
      search,
      dig(components, "subpremise", "long_name"),
      dig(components, "street_number", "long_name"),
      dig(components, "route", "long_name"),
    ),
    locality: dig(components, "locality", "long_name") || dig(components, "sublocality_level_1", "long_name"),
    region: dig(components, "administrative_area_level_1", "short_name"),
    postcode: dig(components, "postal_code", "long_name"),
    country: dig(components, "country", "short_name"),
  };
};
