import {Controller} from "stimulus";
import Choices from "choices.js";

export default class extends Controller {
  connect() {
    /* Don't add choices if it is already connected */
    if (this.element.getAttribute("data-choice") === "active") return;

    new Choices(this.element, {
      itemSelectText: "",
      removeItemButton: false,
      searchResultLimit: 6,
      shouldSort: false,
      searchEnabled: this.element.options.length >= 10,
      searchPlaceholderValue: "Start typing to search...",
      classNames: {
        containerOuter: "pointer-cursor relative outline-0",
        containerInner: "pr-14 text-charcoal text-base",
        groupHeading: "bg-snowflake font-medium text-sm p-4",
        item: "flex truncate text-base px-3 py-2 cursor-pointer",
        itemSelectable: "cursor-pointer",
        itemDisabled: "opacity-75",
        disabledState: "opacity-75",
        highlightedState: "bg-grey-light",
        listDropdown: "choices__list--dropdown border border-solid rounded-md w-max min-w-full max-w-72 bg-white text-charcoal border-manatee-diet outline-0 mt-3 z-20",
        inputCloned: "bg-white text-base text-charcoal outline-none border-0 border-b border-solid border-snowflake-muddy min-w-0 box-border w-full sticky top-0 m-0 p-3",
      },
    });
  }
}
