import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [
    "backgroundCheckHint",
    "category",
    "covidHint",
    "date",
    "fileLabel",
    "identificationHint",
    "loader",
    "name",
    "select",
    "transport",
  ];

  static values = {expiring: Array, tagName: String};

  connect() {
    this.dateTargetInput = this.dateTarget.querySelector("input");
    this.renderFields();
    if (this.hasSelectTarget) this.showCategorySection(this.selectTarget.value);
  }

  handleCategoryChange(event) {
    this.categoryTargets.forEach((el) => el.classList.add("hidden"));
    this.showCategorySection(event.target.value);
  }

  showCategorySection = (categoryId) => {
    const fieldset = this.categoryTargets.find(el => el.dataset.categoryId === categoryId);

    if (fieldset) {
      if (this.selectTarget.querySelector("option[selected]").innerText !== "Other") {
        fieldset.classList.remove("hidden");
      }

      // Select first radio button in section if none selected
      if (!fieldset.querySelector("input[type='radio']:checked")) {
        fieldset.querySelector("input[type=\"radio\"]").click();
      }
    }
  };

  handleChange(event) {
    this.tagNameValue = event.target.parentElement.lastElementChild.innerText;
    this.renderFields();
  }

  renderFields() {
    // Expiry date field
    if (this.expiringValue.indexOf(this.tagNameValue) > -1) {
      this.dateTarget.classList.remove("hidden");
    } else {
      this.dateTarget.classList.toggle("hidden", this.tagNameValue !== "Other");
    }

    // Optional name field for "Other"
    if (this.hasNameTarget) this.nameTarget.classList.toggle("hidden", this.tagNameValue !== "Other");

    // AHPRA Registration default expiry
    if (this.tagNameValue === "AHPRA registration") {
      const ahpraExpiryDate = new Date(Date.UTC(new Date().getFullYear(), 4, 31));
      this.dateTargetInput.value = ahpraExpiryDate.toISOString().split(".")[0];
      if (this.dateTargetInput._flatpickr) this.dateTargetInput._flatpickr.setDate(ahpraExpiryDate);
    } else {
      this.dateTargetInput.value = "";
      if (this.dateTargetInput._flatpickr) this.dateTargetInput._flatpickr.setDate(null);
    }

    // Driver's license transport question
    if (this.hasTransportTarget) this.transportTarget.classList.toggle("hidden", this.tagNameValue !== "Driver's licence");

    if (this.hasFileLabelTarget && !!this.tagNameValue) {
      this.fileLabelTarget.innerText = `Upload your ${this.tagNameValue.replace("Other", "document")}`;
    }

    if (this.hasIdentificationHintTarget) {
      const identificationTypes = ["Australian photo identification card", "Driver's licence", "Passport"];
      this.identificationHintTarget.classList.toggle("hidden", identificationTypes.indexOf(this.tagNameValue) < 0);
    }

    if (this.hasBackgroundCheckHintTarget) {
      const backgroundCheckTypes = ["National police check", "NDIS worker screening"];
      this.backgroundCheckHintTarget.classList.toggle("hidden", backgroundCheckTypes.indexOf(this.tagNameValue) < 0);
    }

    if (this.hasCovidHintTarget) {
      this.covidHintTarget.classList.toggle("hidden", this.tagNameValue.indexOf("COVID-19 vaccine certificate") !== 0);
    }
  }

  handleShowLoader() {
    if (this.hasLoaderTarget) this.loaderTarget.classList.remove("hidden");
  }
}
