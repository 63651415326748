import c0 from './carousel_controller';
import c1 from './company_signup_form_controller';
import c2 from './filterable_select_controller';
import c3 from './modal_controller';
import c4 from './password_input_controller';
import c5 from './phone_input_controller';
import c6 from './popover_controller';
import c7 from './signup_form_controller';
export const definitions = [
	{identifier: 'carousel', controllerConstructor: c0},
	{identifier: 'company-signup-form', controllerConstructor: c1},
	{identifier: 'filterable-select', controllerConstructor: c2},
	{identifier: 'modal', controllerConstructor: c3},
	{identifier: 'password-input', controllerConstructor: c4},
	{identifier: 'phone-input', controllerConstructor: c5},
	{identifier: 'popover', controllerConstructor: c6},
	{identifier: 'signup-form', controllerConstructor: c7},
];
