// @example
// <div is="address-picker">
//  <input is="address-input">
//  <input is="lat">
//  <input is="lng">
// </div>

const initAddressPicker = (el) => {
  const input = el.querySelector("[is='address-input']");
  const lat = el.querySelector("[is='lat']");
  const lng = el.querySelector("[is='lon']");

  const autocomplete = new window.google.maps.places.Autocomplete(
    input,
    {fields: ["geometry.location"]}
  );

  input.addEventListener("change", (event) => {
    if (event.target.value === "") {
      delete el.dataset.address;
      lat.value = "";
      lng.value = "";
    }
  });

  input.addEventListener("keydown", (event) => {
    if (event.key === "Enter") event.preventDefault();
    if (event.key === "Tab") selectFirstPlacesResult();
  });

  input.addEventListener("blur", () => {
    if (el.dataset.address) {
      // Set to address value in memory
      const location = autocomplete.getPlace().geometry.location;
      input.value = el.dataset.address;
      lat.value = location.lat();
      lng.value = location.lng();
    } else {
      // Empty input value if no address value in memory and if no option selected
      input.value = "";
      input.dispatchEvent(new window.Event("change", {bubbles: true}));
    }
  });

  const selectFirstPlacesResult = () => {
    const arrowDownKeyEvent = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: true,
      key: "Down",
      code: "Down",
      keyCode: 40,
      which: 40,
    });
    const enterKeyEvent = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: true,
      key: "Enter",
      code: "Enter",
      keyCode: 13,
      which: 13,
    });

    window.google.maps.event.trigger(input, "keydown", arrowDownKeyEvent);
    window.google.maps.event.trigger(input, "keydown", enterKeyEvent);
    el.dataset.address = input.value;
  };

  autocomplete.addListener("place_changed", () => {
    const location = autocomplete.getPlace().geometry.location;
    lat.value = location.lat();
    lng.value = location.lng();
    el.dataset.address = input.value;
  });
};

document.addEventListener("turbo:morph", () => {
  document.querySelectorAll("[is='address-picker']").forEach(initAddressPicker);
});

export default window.ondocumentadd("[is='address-picker']", initAddressPicker);
