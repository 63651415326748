import {Controller} from "stimulus";
import {mapGooglePlaceAddressComponentsToAddress} from "src/common/google_map_helpers";

const approximateTimezone = function(utcOffsetMinutes, HTMLOptions) {
  const options = Array.prototype.slice.call(HTMLOptions); // Coerce NodeSet into Array
  const option = options.find(function(option) {
    return Number(option.dataset.utcOffsetMinutes) === utcOffsetMinutes;
  });
  if (option) {
    return option.value;
  }
};

const regionalTimezone = function(country, region) {
  if (!country) {
    return;
  }

  switch (country) {
  case "AU":
    switch (region) {
    case "QLD":
      return "Brisbane";
    case "NSW":
      return "Sydney";
    case "ACT":
      return "Canberra";
    case "VIC":
      return "Melbourne";
    case "TAS":
      return "Hobart";
    case "SA":
      return "Adelaide";
    case "WA":
      return "Perth";
    case "NT":
      return "Darwin";
    }
    break;
  case "NZ":
    return "Auckland";
  }
};

export default class AutocompleteAddressController extends Controller {
  static targets = ["search", "street1", "street2", "locality", "region", "postcode", "timezone", "latitude", "longitude", "country"];
  static values = {
    clearSearch: {type: Boolean, default: true},
    fields: Array,
    restrictions: Object,
    types: Array,
  };

  connect() {
    if (typeof (window.google?.maps?.places) != "undefined") {
      this.initializeAutocomplete();
    }
  }

  initializeAutocomplete() {
    if (this.autocomplete != undefined) return;

    const placeholder = this.searchTarget.getAttribute("placeholder");
    const defaultFields = ["address_components", "utc_offset_minutes"]; // Limiting results to address components reduces usage/billing.
    const defaultTypes = ["address"]; // Declare we are only compatible with address types, not geocoding or business types.

    this.pristineSearchTarget = this.searchTarget.cloneNode(true); // Take a copy of search target before the widget mutates it
    this.autocomplete = new window.google.maps.places.Autocomplete(this.searchTarget, {
      fields: this.hasFieldsValue ? this.fieldsValue : defaultFields,
      types: this.hasTypesValue ? this.typesValue : defaultTypes,
      componentRestrictions: this.hasRestrictionsValue ? this.restrictionsValue : null,
    });
    this.autocomplete.addListener("place_changed", this.fillIn);
    this.searchTarget.placeholder = placeholder || ""; // Replace the placeholder that the widget inserts
  }

  disconnect() {
    // Unbind events
    if (typeof (window.google?.maps?.event) != "undefined") {
      window.google.maps.event.clearInstanceListeners(this.searchTarget);
      window.google.maps.event.clearInstanceListeners(this.autocomplete);
    }
    delete this.autocomplete;

    // Restore address target to pristine state
    this.searchTarget.parentNode.replaceChild(this.pristineSearchTarget, this.searchTarget);
    delete this.pristineSearchTarget;
  }

  fillIn = (_) => {
    const place = this.autocomplete.getPlace();
    if (!place && !place.address_components) {
      return;
    }

    // Fill in address
    const address = mapGooglePlaceAddressComponentsToAddress(place.address_components, this.searchTarget.value);
    if (address.street1 && this.hasStreet1Target) {
      this.street1Target.value = address.street1;
    }
    if (address.locality && this.hasLocalityTarget) {
      this.localityTarget.value = address.locality;
    }
    if (address.region && this.hasRegionTarget) {
      this.regionTarget.value = address.region;
    }
    if (address.postcode && this.hasPostcodeTarget) {
      this.postcodeTarget.value = address.postcode;
    }
    if (place.geometry && this.hasLatitudeTarget) {
      this.latitudeTarget.value = place.geometry.location.lat();
    }
    if (place.geometry && this.hasLongitudeTarget) {
      this.longitudeTarget.value = place.geometry.location.lng();
    }
    if (this.hasTimezoneTarget) {
      const timezone = regionalTimezone(address.country, address.region) || approximateTimezone(place.utc_offset_minutes, this.timezoneTarget.options);
      if (timezone) {
        if (this.timezoneTarget.dataset.choice === "active") {
          this.timezoneTarget._choices.setChoiceByValue(timezone);
        } else {
          this.timezoneTarget.value = timezone;
        }
      }
    }
    if (address.country && this.hasCountryTarget && ["AU", "NZ"].indexOf(address.country) > -1) {
      this.countryTarget.value = address.country.toLowerCase();
    }
    // Clear and move to next field to signal visually success
    if (this.clearSearchValue && this.localityTarget !== this.searchTarget) {
      this.searchTarget.value = "";
    }

    if (this.hasStreet1Target) {
      this.street1Target.focus();
    }
  };

  pressingEnterDoesNotSubmit = (event) => {
    if (event.keyCode === 13) { // Enter
      event.preventDefault();
    }
  };
}
