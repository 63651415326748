import {Controller} from "stimulus";
import FloatingPositionerController from "./positioner_controller";

/*
  Offer a configurable way to dismiss/hide elements with common actions
  Intended for use with the floating-positioner Stimulus controller, but it's safe to use without.
  Extension ideas:
  - Allow users to define classes that get added/removed on show/hide
  - Use a target instead of the controller element to allow more control over what's being hidden
 */
export default class FloatingVisibilityController extends Controller {
  static values = {
    // whether it needs to call hide() on escape keyup
    escapeKey: {
      type: Boolean,
      default: false,
    },
    // whether it needs to call hide() when the user clicks outside the element tree
    clickOutside: {
      type: Boolean,
      default: false,
    },
    // attributes to add on show and remove on hide
    visibleAttributes: {
      type: Object,
      default: {},
    },
  };

  static outlets = ["with-floating--positioner"];

  declare withFloatingPositionerOutlet: FloatingPositionerController;
  declare hasWithFloatingPositionerOutlet: boolean;

  declare escapeKeyValue: boolean;
  declare clickOutsideValue: boolean;

  declare visibleAttributesValue: object;

  connect() {
    if (this.escapeKeyValue) {
      document.addEventListener("keyup", this.escape);
    }

    if (this.clickOutsideValue) {
      document.addEventListener("click", this.clickOutside);
    }
  }

  disconnect() {
    document.removeEventListener("click", this.clickOutside);
    document.removeEventListener("keyup", this.escape);
  }

  show = () => {
    Object.entries(this.visibleAttributesValue).forEach(
      ([key, value]) => {
        this.element.setAttribute(key, value);
      }
    );

    if (this.hasWithFloatingPositionerOutlet) {
      this.withFloatingPositionerOutlet.position();
    }
  };

  hide = () => {
    Object.entries(this.visibleAttributesValue).forEach(
      ([key, _]) => {
        this.element.removeAttribute(key);
      }
    );

    if (this.hasWithFloatingPositionerOutlet) {
      this.withFloatingPositionerOutlet.cleanup();
    }
  };

  clickOutside = ({target}) => {
    if (!this.element.contains(target)) {
      this.hide();
    }
  };

  escape = ({keyCode}) => {
    if (keyCode === 27) {
      this.hide();
    }
  };
}
