import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["avatar", "canvas", "camera", "form", "input", "loader", "submit", "video"];

  toggleCamera() {
    this.formTarget.classList.toggle("hidden");
    this.cameraTarget.classList.toggle("hidden");

    // Stop camera input if applicable
    if (this.stream) {
      const [track] = this.stream.getVideoTracks();
      track.stop();
      this.videoTarget.pause();
    }
  }

  startCameraStream() {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          aspectRatio: {exact: 1},
          facingMode: "user",
          width: 600,
          height: 600,
        },
        audio: false,
      })
      .then((stream) => {
        this.stream = stream;
        this.videoTarget.play();
        this.videoTarget.srcObject = stream;
      })
      .catch((error) => {
        if (error.message === "Permission denied") {
          this.toggleCamera();
          alert("You must give permission to use your camera to take a photo!");
        }
      });
  }

  takePhoto() {
    const context = this.canvasTarget.getContext("2d");
    const [track] = this.stream.getVideoTracks();
    const {width, height} = track.getSettings();

    context.drawImage(this.videoTarget, 0, 0, width, height);

    this.canvasTarget.toBlob(
      (blob) => {
        const file = new File([blob], `photo-${new Date().toISOString()}.jpg`, {type: "image/jpeg"});
        const dataTransfer = new DataTransfer();

        dataTransfer.items.add(file);
        this.inputTarget.files = dataTransfer.files;
        this.showPreviewAndSubmitForm(file);
        this.toggleCamera();
      },
      "image/jpeg",
      1
    );
  }

  update(event) {
    const [file] = event.target.files;

    this.showPreviewAndSubmitForm(file);
  }

  showPreviewAndSubmitForm(file) {
    // Show image preview
    this.avatarTarget.style.background = `url(${URL.createObjectURL(file)}) center center / cover no-repeat`;
    this.avatarTarget.querySelector("img").style.opacity = 0;

    // Show loading spinner
    this.loaderTarget.classList.remove("hidden");

    // Submit form
    this.submitTarget.click();

    // Disable form UI
    this.element.querySelectorAll("button, input, label").forEach((el) => {
      el.style.pointerEvents = "none";
    });
  }
}
