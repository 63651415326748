import {Controller} from "stimulus";
import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.au";

export default class extends Controller<HTMLInputElement> {
  initialize() {
    new Cleave(this.element, {
      phone: true,
      phoneRegionCode: "AU",
    });
  }
}
