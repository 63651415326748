import {Controller} from "stimulus";

const formatDocumentType = fileType => {
  switch (fileType) {
  case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
  case "application/msword":
    return "DOC";
  case "text/plain":
    return "TXT";
  default:
    return fileType.split("/")[1].toUpperCase();
  }
};

const formatFileSize = bytes => {
  if (bytes < 1000000) return `${(bytes / 1000).toFixed()} KB`;
  return `${(bytes / 1000000).toFixed(2)} MB`;
};

export default class extends Controller {
  static targets = ["input", "form", "preview", "filename", "fileinfo", "thumb"];
  static values = {hasFile: Boolean};

  handleChange() {
    const [file] = this.inputTarget.files;

    this.hasFileValue = !!file;

    if (this.hasFileValue) {
      this.filenameTarget.innerText = file.name;
      this.fileinfoTarget.innerText = [
        formatDocumentType(file.type),
        formatFileSize(file.size),
      ].join(", ");

      if (file.type.indexOf("image") > -1) {
        this.thumbTarget.style.backgroundImage = `url(${URL.createObjectURL(file)})`;
        this.thumbTarget.querySelector("svg").style.opacity = 0;
      } else {
        this.thumbTarget.style.backgroundImage = "";
        this.thumbTarget.querySelector("svg").style.opacity = 1;
      }
    }

    this.formTarget.classList.toggle("hidden", this.hasFileValue);
    this.previewTarget.classList.toggle("hidden", !this.hasFileValue);
  }

  handleClear() {
    this.inputTarget.value = "";

    // Fire change event
    const changeEvent = new Event("change");
    this.inputTarget.dispatchEvent(changeEvent);
  }
}
