import {Controller} from "stimulus";

export default class extends Controller {
  uncheck_children_on_check(event) {
    // Uncheck all checkboxes within section (e.g. Nursing section)
    if (!event.target.checked) {
      event.currentTarget
        .closest("div")
        .querySelectorAll("input[type='checkbox']")
        .forEach((el) => (el.checked = false));
    }
  }
}
