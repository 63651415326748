import {Controller} from "stimulus";
import {mapGooglePlaceAddressComponentsToAddress} from "src/common/google_map_helpers";

export default class AutocompleteSuburb extends Controller<HTMLInputElement> {
  declare searchTarget: HTMLInputElement;
  declare localityTarget: HTMLInputElement;
  declare latitudeTarget: HTMLInputElement;
  declare longitudeTarget: HTMLInputElement;
  declare postcodeTarget: HTMLInputElement;
  declare regionTarget: HTMLInputElement;
  declare timezoneTarget: HTMLInputElement;

  declare pristineSearchTarget?: Node;
  declare autocomplete?: google.maps.places.Autocomplete;

  declare fieldsValue: string[];
  declare typesValue: string[];

  declare hasFieldsValue: boolean;
  declare hasTypesValue: boolean;

  static search = "search";
  static locality = "locality";
  static nullableTargets = ["latitude", "longitude", "postcode", "region", "timezone"];
  static targets = [this.search, this.locality, ...this.nullableTargets];
  static values = {
    fields: Array,
    types: Array,
  };

  connect() {
    const placeholder = this.searchTarget.getAttribute("placeholder") || "";
    const defaultFields = ["address_components", "utc_offset_minutes"]; // Limiting results to address components reduces usage/billing.
    const defaultTypes = ["address"]; // Declare we are only compatible with address types, not geocoding or business types.

    this.pristineSearchTarget = this.searchTarget.cloneNode(true); // Take a copy of search target before the widget mutates it
    this.autocomplete = new window.google.maps.places.Autocomplete(this.searchTarget, {
      fields: this.hasFieldsValue ? this.fieldsValue : defaultFields,
      types: this.hasTypesValue ? this.typesValue : defaultTypes,
    });
    this.autocomplete.addListener("place_changed", this.fillIn);
    this.searchTarget.addEventListener("input", this.clearFieldsOnInput);
    this.searchTarget.placeholder = placeholder; // Replace the placeholder that the widget inserts
  }

  disconnect() {
    // Unbind events
    window.google.maps.event.clearInstanceListeners(this.searchTarget);
    window.google.maps.event.clearInstanceListeners(this.autocomplete || {});
    this.searchTarget.removeEventListener("input", this.clearFieldsOnInput);
    delete this.autocomplete;

    // Restore address target to pristine state
    this.searchTarget.parentNode?.replaceChild(this.pristineSearchTarget!, this.searchTarget);
    delete this.pristineSearchTarget;
  }

  fillIn = (_) => {
    const place = this.autocomplete?.getPlace();
    if (!place?.address_components) { return; }

    // Fill in address
    const address = mapGooglePlaceAddressComponentsToAddress(place.address_components, this.searchTarget.value);
    this.localityTarget.value = address.locality || "";
    this.postcodeTarget.value = address.postcode || "";
    this.regionTarget.value = address.region || "";
    this.latitudeTarget.value = place.geometry?.location?.lat().toString() || "";
    this.longitudeTarget.value = place.geometry?.location?.lng().toString() || "";
    if (address.country && address.region && this.timezoneTarget) {
      this.timezoneTarget.value = this.regionalTimezone(address.country, address.region) || "";
    }
  };

  regionalTimezone = (country: string, region: string) => {
    if (!country) {
      return;
    }

    switch (country) {
    case "AU":
      switch (region) {
      case "QLD":
        return "Brisbane";
      case "NSW":
        return "Sydney";
      case "ACT":
        return "Canberra";
      case "VIC":
        return "Melbourne";
      case "TAS":
        return "Hobart";
      case "SA":
        return "Adelaide";
      case "WA":
        return "Perth";
      case "NT":
        return "Darwin";
      }
      break;
    case "NZ":
      return "Auckland";
    }
  };

  clearFieldsOnInput = (_) => {
    AutocompleteSuburb.nullableTargets.forEach((target) => {
      this[`${target}Target`].value = null;
    });
  };

  pressingEnterDoesNotSubmit = (event: KeyboardEvent) => {
    if (event.keyCode === 13) { // Enter
      event.preventDefault();
    }
  };
}
