import Bugsnag from "@bugsnag/js";

const userMeta = window.UserMeta || {};

Bugsnag.start({
  apiKey: window.AppMeta.bugsnagApiKey,
  enabledReleaseStages: ["production", "staging"],
  releaseStage: window.AppMeta.releaseStage,
  appVersion: window.AppMeta.herokuReleaseVersion,
  user: {id: userMeta.uuid},
  enabledBreadcrumbTypes: ["error", "log", "navigation"],
  onError(event) {
    if (event.originalError.message.match(/MtPopUpList/)) {
      return false;
    }
  },
});

Bugsnag.addMetadata("user", "company_id", userMeta.companyId);
