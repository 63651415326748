import {Controller} from "stimulus";

export default class RemovableNestedAttributesController extends Controller<HTMLElement> {
  static targets = ["destroyFlagField"];

  declare destroyFlagFieldTarget: HTMLInputElement;
  declare readonly hasDestroyFlagFieldTarget: boolean;

  remove() {
    if (this.hasDestroyFlagFieldTarget) {
      this.destroyFlagFieldTarget.value = "true";
      this.element.setAttribute("hidden", "true");
      this.element.classList.add("hidden");
    } else {
      this.element.remove();
    }
  }

  handleBulkRemove(event) {
    if (
      event.detail.controller === this.identifier &&
      event.target === this.element
    ) {
      this.remove();
    }
  }
}
