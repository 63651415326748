// Prevent attachments from being accepted by the Trix editor
// Any files dropped onto the editor will be ignored
// The attachment toolbar button is hidden
//
// Use from a ruby template
// <%= f.rich_text_area data: {controller: "disable-attachments", target: "disable-attachments.field"} %>
//
// If you add a rich_text_area without this controller (or remove it from an existing rich_text_area)
// you will need to create Polices described in
// https://github.com/thelookoutway/lookout/pull/4993
//
// Technique for ignoring files described in https://github.com/basecamp/trix#storing-attached-files
import {Controller} from "stimulus";

export default class DisableAttachmentsController extends Controller {
  static targets = ["field"];

  connect() {
    this.fieldTargets.forEach(field => {
      field.addEventListener("trix-file-accept", function(event) {
        event.preventDefault();
      });

      const toolbar = field.querySelector("trix-toolbar");
      if (field.editor) {
        // trix initialized before this controller connected
        this.hideAttachmentButton(field.editor.element.toolbarElement);
      } else if (toolbar) {
        // trix has initialized but not assigned field.editor yet
        this.hideAttachmentButton(toolbar);
      } else {
        // trix hasn't initialized yet
        field.addEventListener("trix-initialize", (event) => {
          this.hideAttachmentButton(event.target.toolbarElement);
        });
      }
    });
  }

  hideAttachmentButton(toolbarElement) {
    toolbarElement.querySelector(".trix-button-group--file-tools").style.display = "none";
  }
}
