import {Controller} from "stimulus";
import Swiper from "swiper";
import {Keyboard, Navigation, Pagination} from "swiper/modules";

export default class extends Controller {
  static values = {
    autoHeight: {type: Boolean, default: true},
    navigation: {type: Boolean, default: false},
    pagination: {type: Boolean, default: true},
  };

  connect() {
    // Disable pagination and navigation for
    if (this.element.children.length < 2) {
      this.navigationValue = false;
      this.paginationValue = false;
    }

    // DOM setup before initialisation
    this.element.classList.add("swiper");
    Array.from(this.element.children).forEach((child) =>
      child.classList.add("swiper-slide")
    );
    this.element.innerHTML = `
      <div class="swiper-wrapper items-center">
        ${this.element.innerHTML}
      </div>
    `;

    if (this.paginationValue) {
      this.element.innerHTML = `
        ${this.element.innerHTML}
        <div class="swiper-pagination flex mt-5 items-center justify-center space-x-1.5 z-10"></div>
      `;
    }

    if (this.navigationValue) {
      this.element.innerHTML = `
        ${this.element.innerHTML}
        <button type="button" class="swiper-prev bg-transparent border-0 absolute z-10 inset-y-1/2 w-12 -mt-6 h-12 overflow-hidden -indent-96 cursor-pointer before:content-[''] before:border-solid before:border-2 before:border-white before:absolute before:inset-1/2 before:rotate-45 before:-m-2 before:w-4 before:h-4 before:border-t-0 before:border-r-0 left-4">Previous</button>
        <button type="button" class="swiper-next bg-transparent border-0 absolute z-10 inset-y-1/2 w-12 -mt-6 h-12 overflow-hidden -indent-96 cursor-pointer before:content-[''] before:border-solid before:border-2 before:border-white before:absolute before:inset-1/2 before:rotate-45 before:-m-2 before:w-4 before:h-4 before:border-b-0 before:border-l-0 right-4">Next</button>
      `;
    }

    // Initialise Swiper
    this.swiper = new Swiper(this.element, {
      modules: [Keyboard, Navigation, Pagination],
      autoHeight: this.autoHeightValue,
      loop: true,
      keyboard: true,
      navigation: {
        enabled: this.navigationValue,
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      pagination: {
        bulletActiveClass: "!bg-charcoal",
        bulletClass:
          "block cursor-pointer w-2 h-2 rounded-full bg-manatee-diet",
        clickable: true,
        el: ".swiper-pagination",
        enabled: this.paginationValue,
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
  }
}
