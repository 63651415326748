import c0 from './recruitment/candidates/profile_visibility_controller';
import c1 from './recruitment/candidates/progress_controller';
import c2 from './recruitment/form/candidate_role_selection_controller';
import c3 from './recruitment/form/document_file_controller';
import c4 from './recruitment/form/document_tag_select_controller';
import c5 from './recruitment/form/dropzone_controller';
import c6 from './recruitment/form/edit_avatar_controller';
import c7 from './recruitment/form/edit_candidate_preferences_controller';
import c8 from './recruitment/form/edit_hero_title_controller';
import c9 from './recruitment/form/input/date_controller';
import c10 from './recruitment/modal_controller';
import c11 from './recruitment/openings/criteria_controller';
import c12 from './recruitment/tag_cloud/tag_controller';
export const definitions = [
	{identifier: 'recruitment--candidates--profile-visibility', controllerConstructor: c0},
	{identifier: 'recruitment--candidates--progress', controllerConstructor: c1},
	{identifier: 'recruitment--form--candidate-role-selection', controllerConstructor: c2},
	{identifier: 'recruitment--form--document-file', controllerConstructor: c3},
	{identifier: 'recruitment--form--document-tag-select', controllerConstructor: c4},
	{identifier: 'recruitment--form--dropzone', controllerConstructor: c5},
	{identifier: 'recruitment--form--edit-avatar', controllerConstructor: c6},
	{identifier: 'recruitment--form--edit-candidate-preferences', controllerConstructor: c7},
	{identifier: 'recruitment--form--edit-hero-title', controllerConstructor: c8},
	{identifier: 'recruitment--form--input--date', controllerConstructor: c9},
	{identifier: 'recruitment--modal', controllerConstructor: c10},
	{identifier: 'recruitment--openings--criteria', controllerConstructor: c11},
	{identifier: 'recruitment--tag-cloud--tag', controllerConstructor: c12},
];
